import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Tooltip, Typography } from '@mui/material';

const AccessedApps = (props: any) => {
  return props.clickedApps.map((card: any, index: number) => (
    <Grid
      item
      key={index}
      xs={12}
      sm={6}
      md={3}
      lg={3}
      style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Card
        sx={{
          display: 'flex',
          width: '60%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: 1,
          position: 'relative',

          ':hover': {
            boxShadow: 6,
            transform: 'scale(1.05,1.1)',
          },
        }}
      >
        <CardActionArea>
          <CardMedia
            component="div"
            sx={{
              pt: '56.25%',
              objectFit: 'contain',
              margin: '5%',
              width: '90%',
              height: '100%',
            }}
            image={card.logo}
            onClick={() => {
              window.open(card.initiate_login_uri, '_blank');
            }}
            title={card.label}
          />
        </CardActionArea>
        <CardContent>
          <Tooltip title={card?.label}>
            <Typography gutterBottom variant="subtitle1" noWrap>
              {card?.label}
            </Typography>
          </Tooltip>
        </CardContent>
      </Card>
    </Grid>
  ));
};

export default AccessedApps;
