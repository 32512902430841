import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useUpdateUserGroup } from '../../common/hooks/useUpdateUserGroup';

const UnAccessedApps = (props: any) => {
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken!;

  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [Data, Handlers] = useUpdateUserGroup();

  const handleUpdateApps = async (card: any) => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await Handlers.getUpdatedUserGroup(accessToken, card.group_id);
      if (response) {
        console.log('update ', response);
        await props.handleAllApps();
        window.open(card.initiate_login_uri, '_blank');
      }
    } catch (error: any) {
      const errorMessage = error.response?.data.response_code || 'CHAM-ERROR-000';
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return props.nonClickedApps.map((card: any, index: any) => (
    <Grid
      item
      key={index}
      xs={12}
      sm={6}
      md={3}
      lg={3}
      style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Card
        sx={{
          display: 'flex',
          width: '60%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: 1,
          position: 'relative',
          ':hover': {
            boxShadow: 6,
            transform: 'scale(1.05,1.1)',
          },
        }}
      >
        <Paper
          sx={{
            transform: 'rotate(-45deg)',
            width: '80%',
            position: 'absolute',
            transformOrigin: { xs: '65% 300%', sm: '65% 300%', md: '60% 215%;', lg: '70% 320%' },
            zIndex: 1,
            background: 'red',
            color: 'white',
            textAlign: 'center',
            // WebkitAlignContent: 'flex-end',
          }}
        >
          TRY NOW
        </Paper>
        <CardActionArea>
          <CardMedia
            component="div"
            sx={{
              pt: '56.25%',
              //filter: 'blur(6px)',
              margin: '5%',
              objectFit: 'contain',
              width: '90%',
              height: '100%',
            }}
            image={card.logo}
            onClick={() => {
              handleUpdateApps(card);
            }}
            title={card.label}
          ></CardMedia>
        </CardActionArea>
        <CardContent>
          <Tooltip title={card?.label}>
            <Typography gutterBottom variant="subtitle1" noWrap>
              {card?.label}
            </Typography>
          </Tooltip>
        </CardContent>
      </Card>
    </Grid>
  ));
};

export default UnAccessedApps;
