import React from 'react';
import axios from '../../../axios.config';

type Data = {
    loading: boolean;
    errorMessage: string;

};

type Handlers = {
    getUserData: (accessToken: string) => Promise<any>;

};

export function useUserData(): [Data, Handlers] {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const getUserData = async (accessToken: string) => {
        setLoading(true);
        setErrorMessage('');

        try {

            const response = await axios.get(
                '/userApps_func',
                {
                    params: { action: 'get_user_data' },
                    headers: { Authorization: `Bearer ${accessToken}`, }
                }
            );
            //console.log('Get user Data :::: ', response.data)
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data.response_code || 'CHAM-ERROR-000';
            setErrorMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return [

        { loading, errorMessage },
        { getUserData }

    ];
}
