import React from 'react';
import axios from '../../../axios.config'

type Data = {
    loading: boolean;
    errorMessage: string;

};

type Handlers = {
    getAllApps: () => Promise<any>;

};

export function useAllApps(): [Data, Handlers] {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const getAllApps = async () => {
        setLoading(true);
        setErrorMessage('');

        try {

            const response = await axios.get(
                '/userApps_func',
                {
                    params: { action: 'get_all_apps' },
                    // headers: { Authorization: `Bearer ${accessToken}`, }
                }
            );
            console.log('Get All apps:::: ', response.data)
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data.response_code || 'CHAM-ERROR-000';
            setErrorMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return [

        { loading, errorMessage },
        { getAllApps }

    ];
}
