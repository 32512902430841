import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material";
import React from "react";
import AccessedApps from "../AccessedApps";
import { useOktaAuth } from "@okta/okta-react";
import { useAllApps } from "../../common/hooks/useAllApps";
import { useUsersGroups } from "../../common/hooks/useUsersGroups";
import UnAccessedApps from "../UnAccessedApps";

const AllApps = () => {
    const { authState } = useOktaAuth();

    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('')

    const [allApps, setAllApps] = React.useState([]);
    const [accessedApps, setAccessedApps] = React.useState([]);
    const [unaccessedApps, setUnaccessedApps] = React.useState([]);

    const [Data, Handlers] = useAllApps();
    const [data, handlers] = useUsersGroups()

    const accessToken = authState?.accessToken?.accessToken!;

    React.useEffect(() => {
        handleAllApps()
    }, [])

    const handleAllApps = async () => {
        setLoading(true);
        setErrorMessage('');
        try {
            const response = await Handlers.getAllApps();
            if (response) {
                console.log('getAllApps response ::: ', response?.result?.app_list)
                setAllApps(response?.result?.app_list)
                await handleAccessedApps(response?.result?.app_list)
            }
        } catch (error: any) {
            const errorMessage = error.response?.data.response_code || 'CHAM-ERROR-000';
            setErrorMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    }

    const handleAccessedApps = async (allApps: []) => {
        setLoading(true);
        setErrorMessage('');
        try {
            const response = await handlers.getUsersGroups(accessToken);
            console.log('get user groups response ::: ', response.result)
            const clickedApps: any = allApps.filter((app: any) => {
                for (const group of response.result) {
                    if (app.group_id === group.group_id) {
                        return true;
                    }
                }
                return false;
            });

            const nonClickedApps: any = allApps.filter((app) => {
                return !clickedApps.includes(app);
            });

            const sortedClickedApps = clickedApps.sort((a: { user_count: number; }, b: { user_count: number; }) => a.user_count - b.user_count);
            const sortedNonClickedApps = nonClickedApps.sort((a: { user_count: number; }, b: { user_count: number; }) => a.user_count - b.user_count);

            setAccessedApps(sortedClickedApps)

            setUnaccessedApps(sortedNonClickedApps)
        } catch (error: any) {
            const errorMessage = error.response?.data.response_code || 'CHAM-ERROR-000';
            setErrorMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    }
    return (
        <div style={{ minHeight: '100vh' }} id="iframe-id" >
            <Grid container sx={{ py: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item lg={12} sx={{
                    backgroundColor: '#1a1a1a',
                    pt: 1,
                    pb: 1,
                    width: '50%',
                    height: '50%',
                    borderRadius: 2,
                }}>
                    <Typography align="center" gutterBottom sx={{ color: 'white', typography: { sm: 'body1', xs: 'body2', md: 'h5', lg: 'h4' } }}  >
                        AFL iD Supported Apps
                    </Typography>

                </Grid>

                {loading ? (
                    <Grid item xs={12} sm={9} md={12} sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </Grid>
                ) : <Grid container spacing={0.5} sx={{ pt: 2, display: 'flex', justifyContent: 'flex-start', alignItems: "center" }} >
                    <AccessedApps clickedApps={accessedApps} />
                    <UnAccessedApps nonClickedApps={unaccessedApps} handleAllApps={handleAllApps} />
                </Grid>
                }
            </Grid>


            <Box sx={{ flexGrow: 1 }}></Box>

        </div >
    )
}

export default AllApps;