import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import HomePage from 'pages/Home';



const oktaAuth = new OktaAuth({
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER_URL,
  redirectUri: `${window.location.origin}/login/callback`,
  postLogoutRedirectUri: 'https://test-ecb.pulselive.com',
  scopes: ['openid', 'profile', 'email', 'okta.users.read.self'],
  pkce: false,
});


const App = () => {

  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <SecureRoute path="/" exact component={HomePage} />
        <Route path="/login/callback" component={LoginCallback} />
      </Switch>
    </Security>
  );
};

export default App;

