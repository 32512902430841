import React from 'react';
import axios from '../../../axios.config';

type Data = {
    loading: boolean;
    errorMessage: string;

};

type Handlers = {
    getBackgroundImg: (fav_team_name: string) => Promise<any>;

};


export function useBackgroundImg(): [Data, Handlers] {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const getBackgroundImg = async (fav_team_name: string) => {
        setLoading(true);
        setErrorMessage('');

        try {
            const response = await axios.get(
                '/userApps_func',
                {
                    params: {
                        action: 'get_fav_team_img',
                        fav_team_name
                    }

                }
            );

            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data.response_code || 'CHAM-ERROR-000';
            setErrorMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return [

        { loading, errorMessage },
        { getBackgroundImg }

    ];
}
