import { useOktaAuth } from '@okta/okta-react';
import React, { useState } from 'react';
import teamMappings from '../../teamMappings.json';
import { createTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
// import Header from '../../components/Header';
import FooterNew from '../../components/FooterNew';
import { useUserData } from '../../common/hooks/useUserData';
import { useBackgroundImg } from 'common/hooks/useBackgroudImg';
import AllApps from '../../components/AllApps';
import AflImage from '../../assets/AFL iD Profile Background.jpg';

const defaultTheme = createTheme();

const HomePage = () => {
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('')
  const defaultBackgroundImg = `url('${AflImage}')`;
  const { authState } = useOktaAuth();
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const accessToken = authState?.accessToken?.accessToken!;
  const [Data, Handlers] = useUserData();
  const [data, handlers] = useBackgroundImg();
  //let imgPath

  React.useEffect(() => {
    handleUserData()
  }, [])

  const handleBackgroundImg = async (fav_team_name: string) => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await handlers.getBackgroundImg(fav_team_name);
      if (response) {
        console.log("backgroundImage", response.result)
        setBackgroundImageUrl(response.result)
      }

    } catch (error: any) {
      const errorMessage = error.response?.data.response_code || 'CHAM-ERROR-000';
      setErrorMessage(errorMessage);

    } finally {
      setLoading(false);
    }
  };

  const handleUserData = async () => {
    setLoading(true);
    setErrorMessage('');

    try {
      const response = await Handlers.getUserData(accessToken);
      console.log(response?.result?.profile?.c_AFLFavouriteTeams)
      let imageName = ''
      if (response?.result?.profile?.c_AFLFavouriteTeams) {
        await handleBackgroundImg(response?.result?.profile?.c_AFLFavouriteTeams)
      }
    } catch (error: any) {
      const errorMessage = error.response?.data.response_code || 'CHAM-ERROR-000';
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  }


  return (
    <Box>
      {/*  <header>
     <Header />
     </header> */}

      {/* <main> */}
      <Box sx={{
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : defaultBackgroundImg,
        backgroundRepeat: `center center no-repeat`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        height: "100%",
        width: "100%",
      }}>
        <AllApps />
      </Box>
      {/* </main > */}
      {/* <footer>
        <FooterNew />
      </footer> */}
    </Box >
  );
};

export default HomePage;
