import React from 'react';
import axios from '../../../axios.config';

type Data = {
    loading: boolean;
    errorMessage: string;

};

type Handlers = {
    getUpdatedUserGroup: (accessToken: string, group_id: string) => Promise<any>;

};

export function useUpdateUserGroup(): [Data, Handlers] {
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const getUpdatedUserGroup = async (accessToken: string, group_id: string) => {
        setLoading(true);
        setErrorMessage('');

        try {

            const response = await axios.post(
                '/userApps_func',
                {
                    group_id
                },
                {
                    params: { action: 'update_user_group' },
                    headers: { Authorization: `Bearer ${accessToken}`, }
                }
            );
            console.log('Get Update User Group ', response.data)
            return response.data;
        } catch (error: any) {
            const errorMessage = error.response?.data.response_code || 'CHAM-ERROR-000';
            setErrorMessage(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    return [

        { loading, errorMessage },
        { getUpdatedUserGroup }
    ];
}
